import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudAbstract} from '@core/abstracts/crud.abstract';
import {ThemeModel} from '@core/models/api/response/theme.model';


@Injectable({
  providedIn: 'root'
})
export class ThemesService extends CrudAbstract<ThemeModel> {

  constructor(
    protected http: HttpClient
  ) {
    super('themes', http);
  }
}
