import {Pipe, PipeTransform} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {AppState} from '@core/states/app/app.state';
import {LangType} from '@core/types/lang.type';
import {SubjectsService} from '@core/services/api/subjects.service';
import {ThemesService} from '@core/services/api/themes.service';
import {SubthemesService} from '@core/services/api/subthemes.service';
import {CrudAbstract} from '@core/abstracts/crud.abstract';
import {SubjectModel} from '@core/models/api/response/subject.model';
import {ThemeModel} from '@core/models/api/response/theme.model';
import {SubthemeModel} from '@core/models/api/response/subtheme.model';
import {ModulesService} from '@core/services/api/modules.service';


@Pipe({
  name: 'entity'
})
export class EntityPipe implements PipeTransform {

  @Select(AppState.lang) lang$: Observable<LangType>;

  entitiesService: CrudAbstract<SubjectModel | ThemeModel | SubthemeModel>;

  constructor(
    private subjectsService: SubjectsService,
    private themesService: ThemesService,
    private subthemesService: SubthemesService,
    private modulesService: ModulesService,
  ) {
  }

  transform(id: number, key: 'subject' | 'theme' | 'subtheme' | 'module'): Observable<any> {
    switch (key) {
      case 'subject':
        this.entitiesService = this.subjectsService;
        break;
      case 'theme':
        this.entitiesService = this.themesService;
        break;
      case 'subtheme':
        this.entitiesService = this.subthemesService;
        break;
      case 'module':
        this.entitiesService = this.modulesService;
        break;
    }

    return this.entitiesService.get(id).pipe(
      switchMap(item => {
        return this.lang$.pipe(
          map(lang => {
            const name = item[`name_${lang}`];
            if (name) {
              return name;
            }

            const otherLang = lang === 'kk' ? 'ru' : 'kk';
            return item[`name_${otherLang}`];
          })
        );
      })
    );
  }
}
