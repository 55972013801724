import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EntitiesPipe} from '@shared/pipes/entities/entities.pipe';
import {EntityPipe} from '@shared/pipes/entities/entity.pipe';


@NgModule({
  declarations: [
    EntitiesPipe,
    EntityPipe,
  ],
  exports: [
    EntitiesPipe,
    EntityPipe,
  ],
  imports: [
    CommonModule,
  ],
})
export class EntitiesPipeModule {
}
