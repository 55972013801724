import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudAbstract} from '@core/abstracts/crud.abstract';
import {ModuleModel} from '@core/models/api/response/module.model';


@Injectable({
  providedIn: 'root'
})
export class ModulesService extends CrudAbstract<ModuleModel> {

  constructor(
    protected http: HttpClient
  ) {
    super('modules', http);
  }
}
