import {Pipe, PipeTransform} from '@angular/core';

import {NameModel} from '@core/models/local/name.model';
import {Select} from '@ngxs/store';
import {AppState} from '@core/states/app/app.state';
import {Observable, of} from 'rxjs';
import {LangType} from '@core/types/lang.type';
import {map} from 'rxjs/operators';


@Pipe({
  name: 'entities'
})
export class EntitiesPipe implements PipeTransform {

  @Select(AppState.lang) lang$: Observable<LangType>;

  transform(item: NameModel | string): Observable<string> {
    if (typeof item === 'string') {
      return of(item);
    }

    return this.lang$.pipe(
      map(lang => {
        if (!item) {
          return '';
        }

        const name = item[`name_${lang}`];
        if (name) {
          return name;
        }

        const otherLang = lang === 'kk' ? 'ru' : 'kk';
        return item[`name_${otherLang}`];
      })
    );
  }
}
