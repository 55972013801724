import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CrudAbstract} from '@core/abstracts/crud.abstract';
import {SubthemeModel} from '@core/models/api/response/subtheme.model';


@Injectable({
  providedIn: 'root'
})
export class SubthemesService extends CrudAbstract<SubthemeModel> {

  constructor(
    protected http: HttpClient
  ) {
    super('subthemes', http);
  }
}
